type resultsDataType = {
  id: number;
  fullName: string;
  transactionNumber: string;
  transactionValue: string;
  transactionTime: string;
};

const usClients: resultsDataType[] = [
  {
    id: 1,
    fullName: 'Петрова Н.У.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+$120',
    transactionTime: '09:18',
  },
  {
    id: 2,
    fullName: 'Никулес Е.К.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+$320',
    transactionTime: '14:37',
  },
  {
    id: 3,
    fullName: 'Манукян O.B.',
    transactionNumber: 'Дивиденды ***4956',
    transactionValue: '+$250',
    transactionTime: '10:45',
  },
  {
    id: 4,
    fullName: 'Савельев E.M.',
    transactionNumber: 'Дивиденды ***7321',
    transactionValue: '+$180',
    transactionTime: '16:20',
  },
  {
    id: 5,
    fullName: 'Исмаилов Л.Х.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+$300',
    transactionTime: '12:03',
  },
  {
    id: 6,
    fullName: 'Carmen Silva',
    transactionNumber: 'Дивиденды ***7489',
    transactionValue: '+$220',
    transactionTime: '11:29',
  },
  {
    id: 7,
    fullName: 'Темірбеков С.Ф.',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+$150',
    transactionTime: '15:45',
  },
  {
    id: 8,
    fullName: 'Lucia Costa',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+$450',
    transactionTime: '13:10',
  },
  {
    id: 9,
    fullName: 'Абдукалиев О.Р.',
    transactionNumber: 'Дивиденды ***5137',
    transactionValue: '+$280',
    transactionTime: '08:57',
  },
  {
    id: 10,
    fullName: 'Мейрамы Д.Д.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+$350',
    transactionTime: '17:12',
  },
  {
    id: 11,
    fullName: 'Niklas Jovanović',
    transactionNumber: 'Дивиденды ***6743',
    transactionValue: '+$120',
    transactionTime: '14:02',
  },
  {
    id: 12,
    fullName: 'Гагикян Д.Д.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+$200',
    transactionTime: '10:35',
  },
  {
    id: 13,
    fullName: 'Петров В.П.',
    transactionNumber: 'Дивиденды ***8074',
    transactionValue: '+$400',
    transactionTime: '12:46',
  },
  {
    id: 14,
    fullName: 'Elisa Rossi',
    transactionNumber: 'Дивиденды ***4298',
    transactionValue: '+$280',
    transactionTime: '16:58',
  },
  {
    id: 15,
    fullName: 'Саидов Ш.М.',
    transactionNumber: 'Дивиденды ***6043',
    transactionValue: '+$150',
    transactionTime: '09:23',
  },
  {
    id: 16,
    fullName: 'Гарегинян Д.У.',
    transactionNumber: 'Дивиденды ***5864',
    transactionValue: '+$380',
    transactionTime: '11:55',
  },
  {
    id: 17,
    fullName: 'Oliver Müller',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+$220',
    transactionTime: '15:14',
  },
  {
    id: 18,
    fullName: 'Жаксыбеков Л.А.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+$300',
    transactionTime: '13:28',
  },
  {
    id: 19,
    fullName: 'Сырлыбаев А.Т.',
    transactionNumber: 'Дивиденды ***1956',
    transactionValue: '+$120',
    transactionTime: '17:35',
  },
  {
    id: 20,
    fullName: 'Алибекова С.С.',
    transactionNumber: 'Дивиденды ***3712',
    transactionValue: '+$500',
    transactionTime: '08:42',
  },
];

export const data = usClients;
