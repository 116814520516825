import { NavLink, useLocation } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui/button'
import classNames from 'shared/lib/class-names'
import HeroImg from 'shared/assets/images/promotion/HeroImg.webp'
import HeroMobileImg from 'shared/assets/images/promotion/HeroMobileImg.webp'
import ChartIcon from 'shared/assets/icons/ChartIcon.webp'
import BankIcon from 'shared/assets/icons/BankIcon.webp'
import BrokerIcon from 'shared/assets/icons/BrokerIcon.webp'
import CalendarIcon from 'shared/assets/icons/CalendarIcon.webp'
import InvestmentsIcon from 'shared/assets/icons/InvestmentsIcon.webp'
import SecurityIcon from 'shared/assets/icons/SecurityIcon.webp'

import { homeData } from '../model/home'

import cls from './HomePage.module.scss'

export const HomePage = () => {
  const location = useLocation()

  const {
    store: { isMobile, isMobileSmall },
  } = useAppContext()

  return (
    <section className={classNames('flex__col_center', cls.promotion)}>
      <div
        className={classNames('container__desktop', cls.promotion__container)}
      >
        <div className={cls.left_block}>
          {isMobileSmall && (
            <img
              className={cls.hero_img_mobile}
              src={HeroMobileImg}
              alt="HeroMobileImg"
            />
          )}

          <h1 className={cls.title}>
            {!isMobileSmall ? (
              <>
                <p>Стань инвестором</p>
                <p>в Freedom Finance</p>
                <p>
                  и зарабатывай <span>от {homeData.earnFromSum}</span>
                </p>
              </>
            ) : (
              <>
                <p>Стань инвестором Freedom Finance и зарабатывай</p>
                <p>
                  <span>от {homeData.earnFromSum}</span>
                </p>
              </>
            )}
          </h1>
          <p className={cls.desc}>
            Ответьте на 4 вопроса, чтобы получить бонус к Вашему брокерскому
            счету!
          </p>

          {isMobileSmall && (
            <div className={cls.info_block}>
              <div className={cls.info}>
                <img src={CalendarIcon} alt="CalendarIcon" />
                <p>Бесплатное обслуживание</p>
              </div>
              <div className={cls.info}>
                <img src={InvestmentsIcon} alt="InvestmentsIcon" />
                <p>Инвестиции от {homeData.investFromSum}</p>
              </div>
              <div className={cls.info}>
                <img src={SecurityIcon} alt="SecurityIcon" />
                <p>Защита от рисков потери депозита</p>
              </div>
            </div>
          )}

          <NavLink
            to={{
              pathname: `${ROUTES.QUESTIONS}/1`,
              search: location.search,
            }}
          >
            <Button>Пройти опрос</Button>
          </NavLink>

          {!isMobileSmall && (
            <div className={cls.partners}>
              <div className={cls.partners__cards}>
                <div className={cls.card}>
                  <img src={ChartIcon} alt="ChartIcon" />
                  <h3>$4.2 млрд</h3>
                  <p>Рыночная капитализация Холдинга</p>
                </div>

                <div className={cls.card}>
                  <img src={BankIcon} alt="BankIcon" />
                  <h3>10 лет</h3>
                  <p>Средний стаж работы сотрудников в финансовой сфере</p>
                </div>

                <div className={cls.card}>
                  <img src={BrokerIcon} alt="BrokerIcon" />
                  <h3>146К</h3>
                  <p>Действующих брокерских счетов</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className={cls.right_block}>
            <picture className={cls.hero_img}>
              <img src={HeroImg} alt="HeroImg" />
            </picture>
          </div>
        )}
      </div>
    </section>
  )
}
