import { useRef, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import cl from 'classnames'
import ChevronDownIcon from 'shared/assets/icons/ChevronDownIcon.svg'

import type { ApiFormCountry } from '../../model/types/apiFormCountry'
import { apiFormCountries } from '../../model/consts/apiCountries'
import { phoneMaskChange } from '../../lib/helpers/phoneMaskChange'
import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../../form/Form.module.scss'

type InputPhoneProps = {
  modalId?: string
  apiFormData: ApiFormCountry
  setCountry: React.Dispatch<React.SetStateAction<string>>
}

export const InputPhone = (props: InputPhoneProps) => {
  const { modalId, apiFormData, setCountry } = props

  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputName = 'phoneNumber'
  const [isOpenCountries, setIsOpenCountries] = useState<boolean>(false)

  const toggleIsOpenCountries = () => {
    setIsOpenCountries(!isOpenCountries)
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !(event.target as HTMLElement).closest(`.${cls.inputPhone__country}`)
    ) {
      setIsOpenCountries(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  const onSelectCountry = (
    event: React.MouseEvent<HTMLDivElement>,
    country: string,
    resetField: (name: string) => void,
  ) => {
    event.stopPropagation()
    setCountry(country)
    resetField(inputName)
    setIsOpenCountries(false)
  }

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
        // @ts-ignore
        resetField,
        // @ts-ignore
        formState: { errors },
      }) => (
        <div
          className={cl(cls.form__input_block, cls['form__input_block--tel'])}
        >
          <InputMask
            {...register(inputName, { required: true })}
            className={cls.inputPhone}
            id={inputName + modalId}
            name={inputName}
            type="tel"
            placeholder={`${apiFormData.phoneTitle} Мобильный телефон`}
            mask={apiFormData.phoneMask}
            maskPlaceholder=""
            alwaysShowMask={false}
            beforeMaskedStateChange={phoneMaskChange({
              phoneTitle: apiFormData.phoneTitle,
              pastBugPhone: apiFormData.pastBugPhone,
              pastBug8Phone: apiFormData.pastBug8Phone,
            })}
          />
          <span
            className={cls.inputPhone__country}
            onClick={toggleIsOpenCountries}
          >
            <img
              src={apiFormData.icon}
              alt="country"
              className={cls.inputPhone__country__flag}
            />
            <img
              src={ChevronDownIcon}
              alt="chevron"
              className={cls.inputPhone__country__cevron}
            />
          </span>
          {isOpenCountries && (
            <div
              className={cls.inputPhone__countries_dropdown}
              ref={dropdownRef}
              onMouseLeave={toggleIsOpenCountries}
            >
              {apiFormCountries.map((country) => (
                <div
                  key={country.country}
                  className={cls.inputPhone__countries_dropdown_container}
                  onClick={(e) =>
                    onSelectCountry(e, country.country, resetField)
                  }
                >
                  <img src={country.icon} alt="country-select" />
                  <p>
                    {country.phoneTitle} {country.country}
                  </p>
                </div>
              ))}
            </div>
          )}
          {errors?.[inputName] && (
            <p className={cls.errors}>{errors?.[inputName]?.message}</p>
          )}
        </div>
      )}
    </ConnectForm>
  )
}
