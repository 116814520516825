import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui/button'
import DividerIcon from 'shared/assets/icons/DividerIcon.webp'
import DividerActiveIcon from 'shared/assets/icons/DividerActiveIcon.webp'
import DividerSmallIcon from 'shared/assets/icons/DividerSmallIcon.webp'
import DividerSmallActiveIcon from 'shared/assets/icons/DividerSmallActiveIcon.webp'
import ArrowLeftIcon from 'shared/assets/icons/ArrowLeftIcon.webp'
import CheckedStepIcon from 'shared/assets/icons/CheckedStepIcon.webp'

import cls from './QuestionsFooter.module.scss'

interface IsNextButtonDisabled {
  pageId?: string
  isAnswered1?: boolean
  isAnswered2?: boolean
  isAnswered3?: boolean
  isAnswered4?: boolean
}

const checkIsNextDisabled = (props: IsNextButtonDisabled) => {
  const { pageId, isAnswered1, isAnswered2, isAnswered3, isAnswered4 } = props

  if (pageId === '1' && !isAnswered1) {
    return true
  } else if (pageId === '2' && !isAnswered2) {
    return true
  } else if (pageId === '3' && !isAnswered3) {
    return true
  } else if (pageId === '4' && !isAnswered4) {
    return true
  } else return false
}

export const QuestionsFooter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  const isLastQuestion = id && +id === 4

  const {
    store: {
      isMobile,
      isMobileSmall,
      user: { questions },
    },
    dispatch,
  } = useAppContext()

  const isAnswered1 = !!questions?.q1?.answer
  const isAnswered2 = !!questions?.q2?.answer
  const isAnswered3 = !!questions?.q3?.answer
  const isAnswered4 = !!questions?.q4?.answer
  const isAllAnswered = isAnswered1 && isAnswered2 && isAnswered3 && isAnswered4

  const isNextDisabled = checkIsNextDisabled({
    pageId: id,
    isAnswered1,
    isAnswered2,
    isAnswered3,
    isAnswered4,
  })

  const [current, setCurrent] = useState<number>(1)

  const onPagination = (num: number) => {
    setCurrent(num)
  }
  const onNext = () => {
    if (current !== 4) setCurrent(current + 1)

    if (isAllAnswered && isLastQuestion) {
      if (isMobileSmall) {
        navigate({
          pathname: ROUTES.GIFT,
          search: location.search,
        })
      } else {
        navigate({
          pathname: ROUTES.CONTACTS,
          search: location.search,
        })
      }
    }
  }
  const onPrev = () => {
    if (current !== 1) setCurrent(current - 1)

    if (current === 1) {
      navigate({
        pathname: ROUTES.MAIN,
        search: location.search,
      })
    }
  }

  useEffect(() => {
    navigate({
      pathname: ROUTES.QUESTIONS + `/${current}`,
      search: location.search,
    })
  }, [current])

  const dividerIcon = isMobile ? DividerSmallIcon : DividerIcon
  const dividerActiveIcon = isMobile
    ? DividerSmallActiveIcon
    : DividerActiveIcon

  return (
    <div className={cls.footer}>
      <div className={cls.nums}>
        <Button
          className={classNames(cls.num, current === 1 && cls.num__active)}
          onClick={() => onPagination(1)}
          disabled={true}
        >
          {isAnswered1 ? (
            <img
              className={classNames(isAnswered1 && cls.num__check)}
              src={CheckedStepIcon}
              alt="CheckedStepIcon"
            />
          ) : (
            1
          )}
        </Button>
        <img
          src={isAnswered1 ? dividerActiveIcon : dividerIcon}
          alt="DividerIcon"
        />
        <Button
          className={classNames(cls.num, current === 2 && cls.num__active)}
          onClick={() => onPagination(2)}
          disabled={true}
        >
          {isAnswered2 ? (
            <img
              className={classNames(isAnswered2 && cls.num__check)}
              src={CheckedStepIcon}
              alt="CheckedStepIcon"
            />
          ) : (
            2
          )}
        </Button>
        <img
          src={isAnswered1 ? dividerActiveIcon : dividerIcon}
          alt="DividerIcon"
        />
        <Button
          className={classNames(cls.num, current === 3 && cls.num__active)}
          onClick={() => onPagination(3)}
          disabled={true}
        >
          {isAnswered3 ? (
            <img
              className={classNames(isAnswered3 && cls.num__check)}
              src={CheckedStepIcon}
              alt="CheckedStepIcon"
            />
          ) : (
            3
          )}
        </Button>
        <img
          src={isAnswered1 ? dividerActiveIcon : dividerIcon}
          alt="DividerIcon"
        />
        <Button
          className={classNames(
            cls.num,
            current === 4 && cls.num__active,
            isAnswered4 && cls.num__check,
          )}
          onClick={() => onPagination(4)}
          disabled={true}
        >
          {isAnswered4 ? (
            <img
              className={classNames(isAnswered4 && cls.num__check)}
              src={CheckedStepIcon}
              alt="CheckedStepIcon"
            />
          ) : (
            4
          )}
        </Button>
      </div>
      <div className={cls.buttons}>
        <Button className={cls.button_back} onClick={onPrev}>
          <img src={ArrowLeftIcon} alt="ArrowLeftIcon" />
        </Button>
        <Button
          className={classNames(
            cls.button_next,
            isNextDisabled && cls.button_next__disabled,
          )}
          onClick={onNext}
          disabled={isNextDisabled}
        >
          Далее
        </Button>
      </div>
    </div>
  )
}
